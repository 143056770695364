import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, concatMap, map , switchMap, delay, retry, take} from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, Subject, Observable, throwError, Subscription, iif,  of , firstValueFrom, retryWhen, EMPTY } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthModalComponent } from '../auth-modal/auth-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../models/user.model';
import { UserProfile } from '../models/userProfile.model';





// import { MatDialog } from '@angular/material/dialog';
// import { SessionExpiryDialogComponent } from './session-expiry-dialog/session-expiry-dialog.component';

export enum AuthenticationStatus {
  notAuthenticated,
  clientAuthenticated,
  userAuthenticated
}



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    //private apiUrl = environment.apiUrl;
    private apiUrl = environment.apiDnsV1;
    private loggedIn = new BehaviorSubject<boolean>(false);
    private logoutSubject = new Subject<void>();
    private snackBar: MatSnackBar
    //private isLoggedInSubject: BehaviorSubject<boolean>;
    rootApiUrl = environment.rootApiDns;
    accUrl = environment.accountResetUrl;
    csrfUrl = environment.csrf;


  private authStatusSubject = new BehaviorSubject<AuthenticationStatus>(AuthenticationStatus.notAuthenticated);
  authStatus = this.authStatusSubject.asObservable();

  // Initialize the BehaviorSubject with the value from localStorage or false if it doesn't exist
  private isLoggedInSubject = new BehaviorSubject<boolean>(localStorage.getItem('isLoggedIn') === 'true');
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  // Declare a new BehaviorSubject that will hold session ID
  private sessionIdSubject = new BehaviorSubject<string>('');
  public sessionId$ = this.sessionIdSubject.asObservable();
  loginSuccessSubject = new BehaviorSubject<boolean>(false);

  currentUrl: string;
  formData: any;


  getCsrfToken(): Observable<any> {
    return this.http.get(this.csrfUrl, { withCredentials: true });
  }

  public getApiUrl(): string {
    return this.apiUrl;
  }
  

  constructor(private http: HttpClient,
              private router: Router,
              private modalCtrl: ModalController
              //private dialog: MatDialog
    ) {

        // Initialize the BehaviorSubject with the value from localStorage or false if it doesn't exist
        //this.isLoggedInSubject = new BehaviorSubject<boolean>(localStorage.getItem('isLoggedIn') === 'true');

        this.checkTokens();
     }

// Method to fetch user by ID
async getUserById(userId: number): Promise<User> {
  const token = this.getAccessToken();
  const headers = {
    'Authorization': `Bearer ${token}`,
  };

  const userUrl = `${environment.apiUrl}/get_custom_user_by_id/${userId}/`;

  try {
    const user: User = await this.http.get<User>(userUrl, { headers }).toPromise();
    return user;
  } catch (error) {
    //console.error('Error fetching user by ID:', error);
    return null; // Handle error as needed
  }
}


// Method to fetch user by ID
async getUserProfileById(userId: number): Promise<UserProfile> {
  const token = this.getAccessToken();
  const headers = {
    'Authorization': `Bearer ${token}`,
  };

  const userUrl = `${environment.apiUrl}/get_userprofile_by_id/${userId}/`;

  try {
    const userProfile: UserProfile = await this.http.get<UserProfile>(userUrl).toPromise();
    return userProfile;
  } catch (error) {
    //console.error('Error fetching user profileby ID:', error);
    return null; // Handle error as needed
  }
}





private checkTokens(): void {
  // Check for existing tokens in local storage
  const session_id = localStorage.getItem('session_id');
  const user_token = localStorage.getItem('user_token');

  // If the session_id exists, the client is authenticated
  if (session_id) {
    this.authStatusSubject.next(AuthenticationStatus.clientAuthenticated);
  }
  // If the user_token exists, the user is authenticated
  else if (user_token) {
    this.authStatusSubject.next(AuthenticationStatus.userAuthenticated);
  }
  // If neither tokens exist, the user is not authenticated
  else {
    this.authStatusSubject.next(AuthenticationStatus.notAuthenticated);
  }
}


getUUID(): Observable<string> {
  const url = this.rootApiUrl+'/v1/create_session/';

  return this.http.get<{uuid: string}>(url, {}).pipe(
    map(response => {
      // Store the session ID in local storage
      localStorage.setItem('session_id', response.uuid);
      // Also update the session ID in your BehaviorSubject
      this.sessionIdSubject.next(response.uuid);
      this.checkTokens();
      return response.uuid;
    }),
    retry(3), // retry the call 3 times if it fails
    catchError(error => {
      //console.error('Failed to fetch UUID:', error);
      return throwError('Failed to fetch UUID, please try again later.'); // throw the error if the call fails after 3 retries
    })
  );
}


getCurrentSessionId(): string {
  return this.sessionIdSubject.value;
}

  // Method to subscribe to getUUID and manage error
// subscribeUUID(): Subscription {
//     return this.getUUID().subscribe((uuid: string) => {
//       //cosole.log('Received UUID:', uuid);
//     }, (error) => {
//       //console.error('Error fetching UUID:', error);
//     });
//   }

  // subscribeUUID(): Subscription {
  //   return this.getUUID()
  //     .pipe(
  //       catchError((error) => {
  //         console.error('Error fetching UUID:', error);
  //         // Decide how you want to handle errors. You might want to return a special UUID or an empty observable
  //         return of(null); // `of` is an operator that creates an Observable from its arguments
  //       })
  //     )
  //     .subscribe(
  //       (uuid: string) => {
  //         // Handle the UUID
  //       }, 
  //       (error) => {
  //         // This block will only be executed if you throw an error after catching it
  //       }
  //     );
  // }

  subscribeUUID(): Observable<string> {
    return this.getUUID()
      .pipe(
        catchError((error) => {
          console.error('Error fetching UUID:', error);
          return of(null); // `of` is an operator that creates an Observable from its arguments
        })
      );
  }

// login(email: string, password: string): Observable<any> {
//   const url = this.apiUrl+'/login';
//   return this.http.post(url, { email, password }).pipe(
//     tap((response: any) => {
//       localStorage.setItem('access_token', response.access_token);
//       localStorage.setItem('refresh_token', response.refresh_token);
//       this.isLoggedInSubject.next(true);
//       localStorage.setItem('isLoggedIn', 'true');
//       this.authStatusSubject.next(AuthenticationStatus.userAuthenticated);

//       if (response.user) {
//         localStorage.setItem('current_user', JSON.stringify(response.user));
//         //cosole.log('User data saved:', response.user);
//       }

//        // Emit value from loginSuccessSubject indicating that login is successful
//       this.loginSuccessSubject.next(true);
//     }),
//     switchMap(async (response: any) => {
//       await firstValueFrom(this.fetchUserData());
//       //cosole.log(response)
//       return response;
//     })
//   );
// }


handleAuthentication(response: any): void {
  localStorage.setItem('access_token', response.access_token);
  localStorage.setItem('refresh_token', response.refresh_token);
  this.isLoggedInSubject.next(true);
  localStorage.setItem('isLoggedIn', 'true');
  this.authStatusSubject.next(AuthenticationStatus.userAuthenticated);

  // if (response.user) {
  //   localStorage.setItem('current_user', JSON.stringify(response.user));
  // }
  
  // Emit value from loginSuccessSubject indicating that login is successful
  this.loginSuccessSubject.next(true);
}


login(email: string, password: string): Observable<any> {
  const url = this.apiUrl + '/login';
  return this.http.post(url, { email, password }).pipe(
    tap((response: any) => {
      this.handleAuthentication(response);
    }),
    switchMap(async (response: any) => {
      await firstValueFrom(this.fetchUserData());
      return response;
    })
  );
}


facebookLogin(): Observable<any> {
  // You would typically get the access token from the URL or from Facebook SDK
  // But for now, let's assume you have it already
  const accessToken = 'EAADVnM2B5dcBO8b81aQic2Se6dfUSR9QxMTVXzdJZC9Tb5cwiC2UepbZAkL4XVgfbrAZCgBaOiBOBCxshNh4wOshfgz6awCvqSd3JGQCIYY4ZA74zjzqVp4oRpngXVeVNVgzbTg9L3EZCuxZAwKtzWco71iFHS3Y0sfUh96CcAFhSfT764Huy4mBUHc8GrwdvcewZDZD';
  // 49bf56625e1496bf7d527abbdb0bd9b1b8278d22
  return this.http.post(`${environment.apiBase}/dj-rest-auth/facebook/`, { access_token: accessToken })
  .pipe(
      switchMap((response: any) => {
          //cosole.log(response)

          // Store the tokens received from your Django backend in local storage
          localStorage.setItem('access_token', response.key); // Use 'key' instead of 'access_token'

          this.isLoggedInSubject.next(true);
          localStorage.setItem('isLoggedIn', 'true');
          this.authStatusSubject.next(AuthenticationStatus.userAuthenticated);

          // Now make a request to your new endpoint to get the user data
          const headers = new HttpHeaders({
              'Authorization': `Token ${response.key}`
          });
          return this.http.post(`${environment.apiDnsV1}/fb_social_login/`, { access_token: accessToken }, { headers: headers });
          //return this.http.get(`${this.apiUrl}/login`, { headers: headers });
          //return response
      }),
      tap((response: any) => {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);

        this.isLoggedInSubject.next(true);
        localStorage.setItem('isLoggedIn', 'true');
        this.authStatusSubject.next(AuthenticationStatus.userAuthenticated);

        if (response.user) {
          localStorage.setItem('current_user', JSON.stringify(response.user));
          //cosole.log('User data saved:', response.user);
        }

        // Emit value from loginSuccessSubject indicating that login is successful
        this.loginSuccessSubject.next(true);

        //cosole.log(response);
      }),
      switchMap(async (response: any) => {
        await firstValueFrom(this.fetchUserData());
        //cosole.log(response)
        return response;
      })
  );
}

// simple token
// fetchUserData(): Observable<any> {
//   const token = this.getToken();
//   const headers = {
//     'Authorization': `Token ${token}`,
//   };

//   return this.http.get(`${this.apiUrl}/user_profile/`, { headers }).pipe(
//     tap((response: any) => {
//       if (response) {
//         localStorage.setItem('current_user', JSON.stringify(response));
//       }
//     }),
//     catchError((error) => {
//       //console.error('Failed to fetch user data:', error);
//       throw error;
//     })
//   );
// }

fetchUserData(): Observable<any> {
  const token = this.getAccessToken();
  //cosole.log(token)
  const headers = {
    'Authorization': `Bearer ${token}`,
  };

  return this.http.get(`${this.apiUrl}/user_profile/`, { headers }).pipe(
    tap((response: any) => {
      if (response) {
        localStorage.setItem('current_user', JSON.stringify(response));
      }
    }),
    catchError((error) => {
      //console.error('Failed to fetch user data:', error);
      throw error;
    })
  );
}

// no rety logic
// refreshToken(): Observable<any> {
//   const refreshToken = localStorage.getItem('refresh_token');
//   if (refreshToken) {
//     const refreshUrl = `${this.apiUrl}/token/refresh/`; // Update with your refresh token endpoint
//     return this.http.post(refreshUrl, {refresh: refreshToken}).pipe(
//       tap((response: any) => {
//         if (response.access) {
//           localStorage.setItem('access_token', response.access);
//         }
//       }),
//       catchError((error) => {
//         //console.error('Failed to refresh token:', error);
//         throw error;
//       })
//     );
//   } else {
//     return throwError('No refresh token');
//   }
// }

/** 
retry logic goes as follows:
– once if the error is `unauthorized`
– up to 3 times (or remotely overridden) if it’s a server error (5xx)
– 0 times otherwise
*/
refreshToken(): Observable<any> {
  const refreshToken = localStorage.getItem('refresh_token');
  if (refreshToken) {
      const refreshUrl = `${this.apiUrl}/token/refresh/`; // Update with your refresh token endpoint
      return this.http.post(refreshUrl, { refresh: refreshToken }).pipe(
          retryWhen(errors =>
              // Retry up to 3 times upon server error (5xx)
              errors.pipe(
                  concatMap((error, index) =>
                      iif(
                          () => index > 2 || !this.isServerError(error),
                          throwError(error),
                          of(error).pipe(delay(1000))
                      )
                  )
              )
          ),
          tap((response: any) => {
              if (response.access) {
                  localStorage.setItem('access_token', response.access);
              }
          }),
          catchError((error) => {
              //console.error('Failed to refresh token:', error);
              throw error;
          })
      );
  } else {
      return throwError('No refresh token');
  }
}

private isServerError(error: any): boolean {
  // Check if the error status is in the 5xx range
  return error.status >= 500 && error.status < 600;
}


getUser(): Observable<any> {
  const currentUser = localStorage.getItem('current_user');
  if (currentUser) {
    return of(JSON.parse(currentUser));
  }
  return of(null);
}


// isAuthenticated(): boolean {
//   const token = localStorage.getItem('access_token');
//   return !!token;
// }

isAuthenticated(): Observable<boolean> {
  const token = localStorage.getItem('access_token');
  return of(!!token);
}

getIsAuthenticated(): Observable<boolean> {
  return this.isLoggedInSubject.asObservable();
}


  
logout(): void {
  // Clear the user data and token from local storage
  localStorage.removeItem('current_user');
  localStorage.removeItem('token');

  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  this.authStatusSubject.next(AuthenticationStatus.clientAuthenticated);

  this.isLoggedInSubject.next(false);
  localStorage.setItem('isLoggedIn', 'false');
  this.logoutSubject.next();
  // Redirect the user to the login page
  this.router.navigate(['../auth/login']);
}



getLogoutObservable(): Observable<void> {
  return this.logoutSubject.asObservable();
}





registerToDonate(
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string
): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  const registerPayload = {
    email: email,
    first_name: firstName,
    last_name: lastName,
    phone: phoneNumber,
  };

  return this.http.post<any>(`${this.apiUrl}/register_donate`, registerPayload, httpOptions);
}



register(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  verificationMethod: string
): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  const registerPayload = {
    email: email,
    password: password,
    first_name: firstName,
    last_name: lastName,
    phone: phoneNumber,
    verification_method: verificationMethod
  };

  return this.http.post<any>(`${this.apiUrl}/register`, registerPayload, httpOptions);
}

  

  verifyPhone(data: { phone_number: string; verification_code: string }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/phone_verification/`, data);
  }

  resendCode(phoneNumber: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/resend_code/`, { phone_number: phoneNumber });
  }

  isLoggedIn(): boolean {
    //return localStorage.getItem('access_token') !== null;
    return localStorage.getItem('access_token') !== null && localStorage.getItem('refresh_token') !== null;
  }

  getToken(): string | null {
    //return localStorage.getItem('access_token');
    //cosole.log(localStorage.getItem('refresh_token'))
    return localStorage.getItem('refresh_token');
  }

  getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }

  getCurrentUser(): any {
    const currentUser = localStorage.getItem('current_user');
    if (currentUser) {
      return JSON.parse(currentUser);
    }
    return null;
  }


  getAnonymousId(): string {
    let anonymousId = localStorage.getItem('anonymousId');
    if (!anonymousId) {
        anonymousId = this.generateUUID();
        localStorage.setItem('anonymousId', anonymousId);
    }
    return anonymousId;
  }

  generateUUID(): string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }
  


  requestClientJWT(): Observable<any> {
    const url = `${this.apiUrl}/api/token/`; // Replace this URL with  server's JWT request endpoint
    const body = {
        'client_id': 'your_client_id', // Replace with  client ID
        'client_secret': 'your_client_secret', // Replace with client secret
    };

    return this.http.post(url, body).pipe(
        tap((response: any) => {
            // If the response includes a token, store it in localStorage
            if (response && response.token) {
                localStorage.setItem('client_jwt', response.token);
            }
        }),
        catchError((error) => {
            //console.error('Failed to request client JWT:', error);
            return throwError(error);
        })
    );
}

  async getSessionId(): Promise<string> {
    let session_id = localStorage.getItem('session_id');
    let user_token = localStorage.getItem('user_token');

    // Scenario: Session ID exists, client is authenticated
    if (session_id) {
        // ...continue with the session
        return session_id;
    }

    // Scenario: User token exists, user is authenticated
    else if (user_token) {
        try {
            // Force token refresh
            await this.refreshToken().toPromise().then(refreshed_token => {
                if (refreshed_token.access) {
                    // If refresh successful, store the refreshed token
                    localStorage.setItem('user_token', refreshed_token.access);
                    // Request a new session ID
                    this.getUUID().toPromise().then(uuid => {
                        session_id = uuid;
                        localStorage.setItem('session_id', session_id);
                    });
                } else {
                    // If refresh fails, force user to log in again
                    throw new Error("Token refresh failed. Please log in again.");
                }
            });
        } catch (error) {
            //console.error(error);
            // ...Handle error
        }
    }

    // Scenario: Neither session ID nor user token exist, not authenticated
    else {
        // Request a new client JWT
        await this.requestClientJWT().toPromise().then(client_jwt => {
            if (client_jwt) {
                // If client JWT request is successful, retry the original call
                this.getUUID().toPromise().then(uuid => {
                    session_id = uuid;
                    localStorage.setItem('session_id', session_id);
                });
            } else {
                // If client JWT request fails, handle error...
                //console.error("Client JWT request failed.");
                // ...Handle error
            }
        });
    }

    return session_id || '';
}


  
getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      let cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}


async openAuthModal(currentUser: any, returnUrl: string) {
  if (!currentUser) {
    const modal = await this.modalCtrl.create({
      component: AuthModalComponent,
      componentProps: {
        returnUrl: returnUrl
      }
    });

    await modal.present();
  }
}

getCsrfCookie(name: string): string {
  const value = `; ${document.cookie}`;
  //cosole.log(value)
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}


getXCsrfCookie(name: string): string {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      let cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}



  

promptLoginOrSignupIfNotAuth(currentUser, returnUrl: string, alertCtrl: AlertController) {
  // Check if user is authenticated
  if (!currentUser) {
    // User is not authenticated, prompt them to login or signup
    const alert = alertCtrl.create({
      header: 'Not logged in',
      message: 'Please create an account on ETFUNDME or login if you have created account with ETFUNDME',
      buttons: [
        {
          text: 'Login',
          handler: () => {
            this.initiateLoginNoData(returnUrl);
            this.navigateAndRefresh(returnUrl);
          },
        },
        {
          text: 'Signup',
          handler: () => {
            this.initiateSignupNoData(returnUrl);
            this.navigateAndRefresh(returnUrl);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.navigateAndRefresh(returnUrl);
          }
        },
        
      ],
    });

    // Present the alert message
    // alert.then((prompt) => {
    //   prompt.present();
    // });
  }
}


navigateAndRefresh(returnUrl: string) {
  this.modalCtrl.dismiss(); // Dismiss any open modal
  this.router.navigateByUrl(returnUrl).then(() => {
    location.reload(); 
  });
}


saveState(url: string, data: any) {
  this.currentUrl = url;
  this.formData = data;
}

saveStateNoData(url: string) {
  this.currentUrl = url;
}


restoreState(form: FormGroup) {
  if (this.currentUrl && this.formData) {
    this.router.navigateByUrl(this.currentUrl);
    form.setValue(this.formData);
  }
}


initiateLogin(currentUrl: string, formData: any) {
  this.saveState(currentUrl, formData);
  this.router.navigate(['/auth/login']);
}

initiateSignup(currentUrl: string, formData: any) {
  this.saveState(currentUrl, formData);
  this.router.navigate(['/auth/signup']);
}

initiateLoginNoData(currentUrl: string): Observable<any> {
  localStorage.setItem('returnUrl', currentUrl);
  this.saveStateNoData(currentUrl);
  this.router.navigate(['/auth/login']);

  return new Observable((observer) => {
    this.loginSuccessSubject.pipe(take(1)).subscribe(() => {
      const returnUrl = localStorage.getItem('returnUrl');
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
        localStorage.removeItem('returnUrl');
      } else {
        this.router.navigateByUrl('/profile');
      }
      observer.next();
      observer.complete();
    });
  });
}


initiateSignupNoData(currentUrl: string) {
  this.saveStateNoData(currentUrl);
  this.router.navigate(['/auth/signup']);
}


getStateNoData(): string {
  // Get the returnUrl from local storage
  return localStorage.getItem('returnUrl');
}

clearStateNoData() {
  // Remove the returnUrl from local storage
  localStorage.removeItem('returnUrl');
}

// setSessionTimer(expiryTime: number) {
//   // Clear any existing timer
//   if (this.timer) {
//     clearTimeout(this.timer);
//   }

//   // Set a new timer
//   this.timer = setTimeout(() => this.showSessionExpiryDialog(), expiryTime - 5 * 60 * 1000); // 5 minutes before expiry
// }

// private showSessionExpiryDialog() {
//   const dialogRef = this.dialog.open(SessionExpiryDialogComponent);

//   dialogRef.afterClosed().subscribe(result => {
//     if (result === 'extend') {
//       this.refreshToken();
//     } else {
//       this.logout();
//     }
//   });
// }


// getUUID(): Observable<string> {
//   const url = this.rootApiUrl+'/v1/create_session/';
//   //return this.http.get<string>(url)
//   return this.http.get<{uuid: string}>(url, {}).pipe(
//     map(response => {
//       // Store the session ID in local storage
//       localStorage.setItem('session_id', response.uuid);
//       this.checkTokens();
//       return response.uuid;
//     })
//   );
// }


// getUUID(): Observable<string> {
//   const url = this.rootApiUrl+'/v1/create_session/';

//   return this.http.get<{uuid: string}>(url, {}).pipe(
//     map(response => {
//       // Store the session ID in local storage
//       localStorage.setItem('session_id', response.uuid);
//       this.checkTokens();
//       return response.uuid;
//     }),
//     retry(3), // retry the call 3 times if it fails
//     catchError(error => {
//       //console.error('Failed to fetch UUID:', error);
//       return throwError('Failed to fetch UUID, please try again later.'); // throw the error if the call fails after 3 retries
//     })
//   );
// }


  // login(email: string, password: string): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //   };

  //   const loginPayload = {
  //     email: email,
  //     password: password
  //   };

  //   return this.http.post<any>(`${this.apiUrl}/login`, loginPayload, httpOptions).pipe(
  //     map(response => {
  //       if (response && response.access_token) {
  //         // Save the access token in the local storage
  //         localStorage.setItem('access_token', response.access_token);
  //       }
  //       return response;
  //     }),
  //     catchError(error => {
  //       // Handle error response
  //       //console.error('Login failed:', error);
  //       throw error;
  //     })
  //   );
  // }


  



// login(email: string, password: string): Observable<any> {
//   // Replace the URL with the correct one for your Django backend
//   const url = 'http://127.0.0.1:8000/api/v1/login';
//   //const url = `${environment.apiUrl}/api/v1/login`;

//   return this.http.post(url, { email, password }).pipe(
//     tap((response: any) => {
//       console.log(response)
//       localStorage.setItem('access_token', response.access_token);
//     })
//   );
// }

// login(email: string, password: string): Observable<any> {
//   const url = 'http://127.0.0.1:8000/api/v1/login';

//   return this.http.post(url, { email, password }).pipe(
//     tap((response: any) => {
//       console.log(response);
//       localStorage.setItem('access_token', response.access_token);
//       this.isLoggedInSubject.next(true);
//       localStorage.setItem('isLoggedIn', 'true');
//       // Assuming the user data is returned in the response
//       if (response.user) {
//         localStorage.setItem('current_user', JSON.stringify(response.user));
//       }
//     })
//   );
// }

// simple token
// login(email: string, password: string): Observable<any> {
//   //const url = 'http://127.0.0.1:8000/api/v1/login';
//   const url = this.apiUrl+'/login';

//   return this.http.post(url, { email, password }).pipe(
//     tap((response: any) => {
//       console.log(response);
//       localStorage.setItem('access_token', response.access_token);
//       this.isLoggedInSubject.next(true);
//       localStorage.setItem('isLoggedIn', 'true');
//       // Assuming the user data is returned in the response
//       if (response.user) {
//         localStorage.setItem('current_user', JSON.stringify(response.user));
//         console.log('User data saved:', response.user); // Add this line
//       }
//     }),
//     switchMap(async (response: any) => {
//       // Fetch user data after a successful login
//       await firstValueFrom(this.fetchUserData());
//       console.log(response)
//       return response;
//     })
//   );
// }

  // public getAuthHeaders(): HttpHeaders {
  //   const token = localStorage.getItem('access_token');
  //   console.log('Token:', token);
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   return headers;
  // }
  
  // getAuthHeaders(): HttpHeaders {
  //   const token = this.authService.getToken();
  //   console.log('Token:', token);
  //   return new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token}`
  //   });
  // }
  
  // Other methods for handling user profile, password reset, etc.


  // using simple token
  // register(
  //   email: string,
  //   password: string,
  //   firstName: string,
  //   lastName: string,
  //   phoneNumber: string,
  //   verificationMethod: string
  // ): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //   };

  //   const registerPayload = {
  //     email: email,
  //     password: password,
  //     first_name: firstName,
  //     last_name: lastName,
  //     phone: phoneNumber,
  //     verification_method: verificationMethod
  //   };

  //   return this.http.post<any>(`${this.apiUrl}/register`, registerPayload, httpOptions).pipe(
  //     map(response => {
  //       if (response && response.access_token) {
  //         // Save the access token in the local storage
  //         localStorage.setItem('access_token', response.access_token);
  //       }
  //       return response;
  //     }),
  //     catchError(error => {
  //       // Handle error response
  //       //console.error('Registration failed:', error);
  //       throw error;
  //     })
  //   );
  // }




// logout(): void {
//   const token = this.getAccessToken();
//   // const headers = new HttpHeaders({
//   //   'Authorization': `Bearer ${token}`,
//   // });

//   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`)
//   const options = { headers: headers };
//   const url = this.apiUrl+'/logout/';
//   this.http.post(url, {}, options)
//     .pipe(
//       catchError(err => {
//         //console.error('Logout failed', err);
//         // TODO: Handle the error in a user-friendly way
//         return EMPTY;
//       })
//     )
//     .subscribe(() => {
//       // Clear the user data and token from local storage
//       localStorage.removeItem('current_user');
//       localStorage.removeItem('token');
//       localStorage.removeItem('access_token');
//       localStorage.removeItem('refresh_token');
//       this.authStatusSubject.next(AuthenticationStatus.clientAuthenticated);
//       this.isLoggedInSubject.next(false);
//       localStorage.setItem('isLoggedIn', 'false');
//       this.logoutSubject.next();
//       // Redirect the user to the login page
//       this.router.navigate(['../auth/login']);
//     });
// }


// logout(): void {
//   const accessToken = this.getAccessToken();
//   const refreshToken = this.getRefreshToken();
  
//   const headers = new HttpHeaders({
//     'Authorization': `Bearer ${accessToken}`,
//   });
  
//   const url = this.apiUrl+'/logout/';
//   this.http.post(url, { refresh: refreshToken }, { headers: headers })
//     .pipe(
//       catchError(err => {
//         //console.error('Logout failed', err);
//         // TODO: Handle the error in a user-friendly way
//         return EMPTY;
//       })
//     )
//     .subscribe(() => {
//       // Clear the user data and token from local storage
//       localStorage.removeItem('current_user');
//       localStorage.removeItem('token');
//       localStorage.removeItem('access_token');
//       localStorage.removeItem('refresh_token');
//       this.authStatusSubject.next(AuthenticationStatus.clientAuthenticated);
//       this.isLoggedInSubject.next(false);
//       localStorage.setItem('isLoggedIn', 'false');
//       this.logoutSubject.next();
//       // Redirect the user to the login page
//       this.router.navigate(['../auth/login']);
//     });
// }



}
