<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Create Campaign</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="campaign-content">
  <ion-grid>
    <ion-row>
      <ion-col >
        <div class="campaign-form">
          <h4 class="auth-title">
            Create Fundraising 
            <br />
          </h4>



          <form [formGroup]="campaignForm" (ngSubmit)="onSubmit()">
            <ng-container [ngSwitch]="step">
              <!-- Step 1 -->
              <ng-container *ngSwitchCase="1">
                <ion-list class="ion-list">
                  <div class="segment-wrapper">
                    <h3 class="auth-title">
                      Select fundraising category
                    </h3>
                    <section>
                      <ion-segment class="categories-segment" (ionChange)="segmentChanged($event)" scrollable [value]="segmentValue">
                        <ion-segment-button (click)="campaignForm.get('fundraising_categorie').setValue('Individuals')" [class.is-shell]="isShell('Individuals')" value="Individuals">
                          <div>
                            <app-aspect-ratio [ratio]="{w: 1, h: 1}" class="category-image">
                              <app-image-shell animation="spinner" [src]="'./assets/funding/etfundme-menu/Fundraising_for_individuals.png'" [alt]="'tab icon'"></app-image-shell>
                            </app-aspect-ratio>
                            <ion-label class="category-title">Individuals</ion-label>
                          </div>
                        </ion-segment-button>
                        <ion-segment-button (click)="navigateToRegisterOrganization('nonprofit')" [class.is-shell]="isShell('Nonprofits')" value="Nonprofits">
                          <div>
                              <app-aspect-ratio [ratio]="{w: 1, h: 1}"  class="category-image">
                                  <app-image-shell animation="spinner" [src]="'./assets/funding/etfundme-menu/charity_organization.png'" [alt]="'tab icon'"></app-image-shell>
                              </app-aspect-ratio>
                              <ion-label class="category-title">Organizations</ion-label>
                          </div>
                      </ion-segment-button>


            
                      </ion-segment>
                    </section>
                  </div>
                </ion-list>
                <ion-button class="ion-button" (click)="nextStep()">Continue</ion-button>
              </ng-container>

              <!-- Step 2  -->
              <ng-container *ngSwitchCase="2">
                <ion-list>
                  <ion-item>
                    <ion-label>Who are you fundraising for?</ion-label>
                    <ion-select formControlName="fundraising_for">
                      <ion-select-option value="yourself">Yourself</ion-select-option>
                      <ion-select-option value="other_individual">Other Individual</ion-select-option>
                      <ion-select-option value="group_of_individuals">Group Of Individuals</ion-select-option>
                    </ion-select>
                  </ion-item>
                  
                  <!-- Title -->
                  <ion-item>
                    <ion-label position="floating">Title</ion-label>
                    <ion-input formControlName="title" maxlength="100"></ion-input>
                    <ion-note *ngIf="campaignForm.get('title').hasError('sqlInjection')" color="danger">SQL injection detected!</ion-note>
                    <ion-note *ngIf="campaignForm.get('title').hasError('maxlength')" color="danger">Max length is 100 characters!</ion-note>
                  </ion-item>
                  <!-- Description -->
                  <ion-item>
                    <ion-label position="floating">Description</ion-label>
                    <ion-textarea formControlName="description" maxlength="500"></ion-textarea>
                    <ion-note *ngIf="campaignForm.get('description').hasError('sqlInjection')" color="danger">SQL injection detected!</ion-note>
                    <ion-note *ngIf="campaignForm.get('description').hasError('maxlength')" color="danger">Max length is 500 characters!</ion-note>
                  </ion-item>

                  <ion-item class="input-item end-date-item" [button]="true" id="open-datetime" id="open-birthdate-modal">
                    <ion-label color="secondary" position="stacked">Campaign End Date</ion-label>
                    <ion-text class="placeholder" *ngIf="!formattedDate">Select a date</ion-text>
                    <ion-text *ngIf="formattedDate">{{ formattedDate }}</ion-text>
                    <ion-modal trigger="open-birthdate-modal" class="datetime-modal">
                      <ng-template>
                        <ion-content>
                          <ion-datetime
                          formControlName="end_date"
                          presentation="date"
                          (ionChange)="formatDate()" 
                          [showDefaultButtons]="true">
                        </ion-datetime>
                        </ion-content>
                      </ng-template>
                    </ion-modal>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Funding Goal</ion-label>
                    <ion-input formControlName="funding_goal" type="number"></ion-input>
                  </ion-item>
                  
                  
                </ion-list>
                <ion-button (click)="previousStep()">Back</ion-button>
                <ion-button (click)="nextStep()">Continue</ion-button>
              </ng-container>


              <!-- Step 3 -->
              <ng-container *ngSwitchCase="3">
                <ion-label>Destination for Raised Funds</ion-label>
                <ion-list>

                  <ion-item>
                    <ion-label>Country</ion-label>
                    <ion-select formControlName="country">
                      <ion-select-option *ngFor="let c of countryData" [value]="c.name">{{c.name}}</ion-select-option>
                    </ion-select>
                  </ion-item>

                  <!-- State -->
                  <ion-item>
                    <ion-label>State</ion-label>
                    <ion-input formControlName="state" type="string" maxlength="50"></ion-input>
                    <ion-note *ngIf="campaignForm.get('state').hasError('sqlInjection')" color="danger">SQL injection detected!</ion-note>
                    <ion-note *ngIf="campaignForm.get('state').hasError('maxlength')" color="danger">Max length is 50 characters!</ion-note>
                  </ion-item>
                  <!-- City -->
                  <ion-item>
                    <ion-label>City</ion-label>
                    <ion-input formControlName="city" type="string" maxlength="50"></ion-input>
                    <ion-note *ngIf="campaignForm.get('city').hasError('sqlInjection')" color="danger">SQL injection detected!</ion-note>
                    <ion-note *ngIf="campaignForm.get('city').hasError('maxlength')" color="danger">Max length is 50 characters!</ion-note>
                  </ion-item>


                </ion-list>
                <ion-button (click)="previousStep()">Back</ion-button>
                <ion-button class="campaign-btn" type="submit" expand="block" [disabled]="!campaignForm.valid">SUBMIT</ion-button>
              </ng-container>
            </ng-container>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
