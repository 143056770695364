import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnInit {
  @Input() returnUrl: string;

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    ) { }

  ngOnInit() {}

  login() {
    this.modalCtrl.dismiss().then(() => {
      this.authService.initiateLoginNoData(this.returnUrl);
    });
  }
  
  signup() {
    this.modalCtrl.dismiss().then(() => {
      this.authService.initiateSignupNoData(this.returnUrl);
    });
  }
  
  cancel() {
    this.modalCtrl.dismiss();
  }
  
}
