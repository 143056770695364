import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NavController } from '@ionic/angular';
import { LanguageService } from '../../services/language.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('rotate', [
      state('english', style({ transform: 'rotate(0)' })),
      state('amharic', style({ transform: 'rotate(360deg)' })),
      transition('english => amharic', animate('500ms ease-in')),
      transition('amharic => english', animate('500ms ease-out'))
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy{

  selectedLanguage = 'english';
  intervalId: any;
  languageSubscription: Subscription;
  buttonText: string;

  isLoggedIn = false;
  showProfileMenu = false;
  public isMobile: boolean;

  private loginSubscription: Subscription

  //const userId = this.authService.getCurrentUser().user;
 

  constructor(
    private navCtrl: NavController,
    private languageService: LanguageService,
    private authService : AuthenticationService,
    
    ) {

      this.isMobile = window.innerWidth < 768; 

     }  
    
     

  ngOnInit() {
    
    this.loginSubscription = this.authService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.languageService.currentLanguage.subscribe(lang => {
      this.buttonText = this.languageService.getTranslation('START ETFUNDME');
    });
  
    this.languageSubscription = this.languageService.getLanguage().subscribe(language => {
      this.selectedLanguage = language;
      this.buttonText = this.languageService.getTranslation('START ETFUNDME');
    });
    
    this.intervalId = setInterval(() => {   // Store the interval Id so that it can be cleared later
      if (this.selectedLanguage === 'english') {
        this.selectedLanguage = 'amharic';
      } else {
        this.selectedLanguage = 'english';
      }
    }, 2000);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 600;
  }
  
  changeLanguage(language: string) {
    this.languageService.changeLanguage(language);
    this.selectedLanguage = language;
    console.log(this.selectedLanguage);
  }

  start_funding() {
    this.navCtrl.navigateForward('/create-campaign');
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  navigateToProfile() {
    this.navCtrl.navigateForward(['/auth/user']); 
  }

  ngOnDestroy() {

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }

    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    
  }
}





