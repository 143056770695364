// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,
  // apiUrl: 'http://127.0.0.1:8000/api/v1',
  // imgApiUrl: 'http://127.0.0.1:8000',
  //accountResetUrl: 'http://54.159.231.247:8000/accounts/password/reset/',
  accountResetUrl: 'https://www.test.etfundme.com/accounts/password/reset/',
  csrf: 'https://www.test.etfundme.com/set-csrf-cookie/',
  apiDnsV1: 'https://www.test.etfundme.com/api/v1',
  apiBase: 'https://www.test.etfundme.com',
  rootApiDns: 'https://www.test.etfundme.com/api',
  passwordReset: 'https://www.test.etfundme.com/accounts/password/reset/',
  passwordResetDone: 'https://www.test.etfundme.com/accounts/password/reset/done/',

  // apiUrl: 'http://54.159.231.247:8000/api/v1',
  // rootApi: 'http://54.159.231.247:8000/api',
  // imgApiUrl: 'http://54.159.231.247:8000',

  apiUrl: 'https://www.test.etfundme.com/api/v1',
  rootApi: 'https://www.test.etfundme.com/api',
  imgApiUrl: 'https://www.test.etfundme.com',
  
  appShellConfig: {
    debug: false,
    networkDelay: 500
  },

  stripeKey : 'pk_test_4qcGl50nJ4IgHYWp97P0jrLW',
  wordpress_api_url: 'https://wordpress.startapplabs.com/blog/wp-json/wp/v2/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
