<ion-header>
  <ion-toolbar>
    <ion-title>PhoneVerification</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form (ngSubmit)="submitPhoneVerification()" [formGroup]="phoneVerificationForm">
    <!-- Phone number input -->
    <ion-item>
      <ion-label position="floating">Phone Number</ion-label>
      <ion-input formControlName="phone_number" type="tel"></ion-input>
    </ion-item>

    <!-- Verification code input -->
    <ion-item>
      <ion-label position="floating">Verification Code</ion-label>
      <ion-input formControlName="verification_code" type="number" inputmode="numeric" maxlength="8"></ion-input>
    </ion-item>

    <div *ngIf="codeExpired">
      <ion-button (click)="resendCode()" [disabled]="!codeExpired">Resend Code</ion-button>
      <p>The verification code has expired. Please request a new one.</p>
    </div>
    

    <!-- Timer -->
    <div class="timer">
      <h1>{{ timeRemaining }}s</h1>
    </div>

    <!-- Submit button -->
    <ion-button expand="block" type="submit" [disabled]="!phoneVerificationForm.valid">
      Submit
    </ion-button>
  </form>
</ion-content>
