<ion-header>
  <ion-toolbar color="light">
   
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <ion-buttons slot="start">
          <a routerLink="/" class="no-uline">
            <ion-title>
              <ion-icon name="home" *ngIf="isMobile"></ion-icon>
              <span *ngIf="!isMobile">ETFUNDME</span>
            </ion-title>
          </a>
        </ion-buttons>
  
    


    <ion-item slot="end" color="light">
      <ion-label [@rotate]="selectedLanguage">
        {{ selectedLanguage === 'english' ? 'EN' : 'AM' }}
      </ion-label>
      <ion-select (ionChange)="changeLanguage($event.detail.value)">
        <!-- options -->
        <ion-select-option value="english">English</ion-select-option>
        <ion-select-option value="amharic">አማርኛ | In progress</ion-select-option>
        <ion-select-option value="oromo">Oromo | Coming soon</ion-select-option>
        <ion-select-option value="tigrinya">Tigrinya | Coming soon</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-buttons slot="end">

      <ion-buttons slot="end">
            <ion-button *ngIf="isLoggedIn" (click)="toggleProfileMenu()">
              <ion-icon name="person-circle-outline"></ion-icon>
      
            </ion-button>
            <a routerLink="/">
            <ion-button *ngIf="!isLoggedIn" (click)="toggleProfileMenu()">
              
      
            </ion-button>
          </a>
      </ion-buttons>


      <!--
      <ion-button class="etfundme-btn custom-button" (click)="start_funding()" expand="block">START ETFUNDME | የገንዘብ ድጋፍ ጀምር</ion-button>
      -->
      <ion-button class="etfundme-btn custom-button" (click)="start_funding()" expand="block">{{ buttonText }}</ion-button>

    </ion-buttons>
  </ion-toolbar>
</ion-header>