import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private translate: TranslateService) {}

  noSqlInjection(control: FormControl) {
    const value = control.value || '';
    const specialCharacterPattern = /(\%27)|(\')|(\-\-)|(\%23)|(#)|(\=)|(\%3D)|(\%26)|(\&)|(\^)|(\;)|(\!)|(\@)|(\%)|(\*)|(\+)|(\~)|(\`)|(\|)|(\{)|(\})|(\[)|(\])/i;
    const sqlKeywordsPattern = /(?:\b(ALTER|CREATE|DELETE|DROP|SELECT|SHOW|USE|INSERT|INTO|VALUES|UPDATE)\b)/i;
  
    const hasSpecialCharacters = specialCharacterPattern.test(value);
    const hasSqlKeywords = sqlKeywordsPattern.test(value);
  
    return hasSpecialCharacters || hasSqlKeywords ? { sqlInjection: true } : null;
  }
  

  checkLanguage(language: string) {
    return (control: FormControl) => {
        const value = control.value || '';
        if (language === 'en' && /[^a-zA-Z\s]/.test(value)) {
          return { nonEnglishCharacters: true };
        }
        if (language === 'am' && /[^\u1200-\u137F\s]/.test(value)) { // Amharic Unicode range
          return { nonAmharicCharacters: true };
        }
        return null;
      };
  }

  maxLength(length: number) {
    return Validators.maxLength(length);
  }

  // Email Validator
  validEmail(control: FormControl) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(control.value) ? null : { invalidEmail: true };
   }
    
   // Phone Number Validator
   validPhoneNumber(control: FormControl) {
        const regex = /^[0-9]{10,15}$/; // Adjust according to the desired format
        return regex.test(control.value) ? null : { invalidPhoneNumber: true };
    }
    
   // URL Validator
   validURL(control: FormControl) {
        try {
          new URL(control.value);
          return null;
        } catch (_) {
          return { invalidUrl: true };
        }
    }


    updateLanguage(value: string, form: FormGroup): boolean {
      const amharicPattern = /[\u1200-\u137F]/;
      const oromicPattern = /[\u1200-\u137F]/;
      const tigrignaPattern = /[\u1200-\u137F]/;
      const frenchPattern = /[a-zA-Z\u00C0-\u00FF]/;
      const englishPattern = /[a-zA-Z]/;
    
      const hasAmharic = amharicPattern.test(value);
      const hasOromic = oromicPattern.test(value);
      const hasTigrigna = tigrignaPattern.test(value);
      const hasFrench = frenchPattern.test(value);
      const hasEnglish = englishPattern.test(value);
    
      if (hasAmharic || hasOromic || hasTigrigna || hasFrench || hasEnglish) {
        let detectedLanguages = [];
        if (hasAmharic) detectedLanguages.push('AM');
        if (hasOromic) detectedLanguages.push('OR');
        if (hasTigrigna) detectedLanguages.push('TI');
        if (hasFrench) detectedLanguages.push('FR');
        if (hasEnglish) detectedLanguages.push('EN');
    
        // Concatenate the detected languages into a string
        const languageValue = detectedLanguages.join(',');

        console.log(languageValue);
        console.log('Detected languages:', { hasAmharic, hasOromic, hasTigrigna, hasFrench, hasEnglish });
    
        form.get('language').setValue(languageValue);
        return true; // Return true to indicate successful language detection
      } else {
        return false; // Return false to indicate unsupported characters detected
      }
    }
    

  
}
