<app-header></app-header>

<ion-app dir="{{textDir}}">
  <ion-split-pane contentId="menu-content" when="false">
    <!-- Menu for logged-in users -->
    <ion-menu  menuId="loggedOutMenu" *ngIf="isLoggedIn" contentId="menu-content" class="app-sidemenu">
      <!-- Your menu items for logged-out users go here -->

      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">

            <ion-col size="4">
              <a routerLink="/">
                <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                  <app-image-shell class="user-avatar" animation="spinner" [src]="'./assets/funding/etfundme-logo.png'"></app-image-shell>
                </app-aspect-ratio>
              </a>
            </ion-col>


            <ion-col class="user-info-wrapper" size="8">
              <h5 class="user-handle"></h5>
            </ion-col>
          </ion-row>
          <ion-row class="user-stats-wrapper user-details-wrapper">
            <ion-col>
              <span class="user-stat-value"></span>
              <span class="user-stat-name"></span>
            </ion-col>
            <ion-col>
              <span class="user-stat-value"></span>
              <span class="user-stat-name"></span>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <ion-content>

      <ion-list>
          <ion-list-header>
            <ion-label>Settings</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url">
              <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
      </ion-list>

       <ion-list>
          <ion-list-header>
            <ion-label>Authentication</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item (click)="logout()">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>
                Logout
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
      </ion-list>
      </ion-content>
    </ion-menu>
    
    <!-- Menu for logged-out users -->
    <ion-menu  menuId="loggedOutMenu" *ngIf="!isLoggedIn" contentId="menu-content" class="app-sidemenu">
      <!-- Your menu items for logged-out users go here -->

      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">
            <ion-col size="4" >
              <a routerLink="/">
                <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                  <app-image-shell class="user-avatar" animation="spinner" [src]="'./assets/funding/etfundme-logo.png'"></app-image-shell>
                </app-aspect-ratio>
              </a>
            </ion-col>
            <ion-col class="user-info-wrapper" size="8">
              <h3 class="user-name"></h3>
              <h5 class="user-handle"></h5>
            </ion-col>
          </ion-row>
          <ion-row class="user-stats-wrapper user-details-wrapper">
            <ion-col>
              <span class="user-stat-value"></span>
              <span class="user-stat-name"></span>
            </ion-col>
            <ion-col>
              <span class="user-stat-value"></span>
              <span class="user-stat-name"></span>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-list-header>
            <ion-label>Account</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false" *ngFor="let p of publicPages; let i = index">
            <ion-item [routerLink]="p.url">
              <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>



      </ion-content>
    </ion-menu>

    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

