import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AuthModalComponent } from './auth-modal.component';

@NgModule({
  declarations: [AuthModalComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [AuthModalComponent]
})
export class AuthModalModule { }
