import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TempCampaignDataService {
  private campaignData: any;

  constructor() { }

  setCampaignData(data: any) {
    this.campaignData = data;
  }

  getCampaignData() {
    return this.campaignData;
  }

  clearCampaignData() {
    this.campaignData = null;
  }
}
