import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneVerificationPage } from './phone-verification/phone-verification.page';
import { CreateCampaignPage } from './create-campaign/create-campaign.page';
import { ActivityPage } from './activity/activity.page';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/funding/listing',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'auth/signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'auth/user',
    loadChildren: () => import('./user/profile/user-profile.module').then(m => m.UserProfilePageModule)
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'forms-and-validations',
    loadChildren: () => import('./forms/validations/forms-validations.module').then(m => m.FormsValidationsPageModule)
  },
  {
    path: 'forms-filters',
    loadChildren: () => import('./forms/filters/forms-filters.module').then(m => m.FormsFiltersPageModule)
  },
  {
    path: 'firebase',
    redirectTo: 'firebase/auth/sign-in',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  },
  {
    path: 'phone-verification',
    loadChildren: () => import('./phone-verification/phone-verification.module').then( m => m.PhoneVerificationPageModule)
  },
  {
    path: 'create-campaign',
    loadChildren: () => import('./create-campaign/create-campaign.module').then( m => m.CreateCampaignPageModule)
  },
  {
    path: 'create-campaign',
    component: CreateCampaignPage,
  },
  {
    path: 'app',
    children: [
      {
        path: 'activity',
        loadChildren: () =>
          import('./activity/activity.module').then((m) => m.ActivityPageModule),
      },
      // Add other child routes
    ],
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'fundme',
    loadChildren: () => import('./fundme/fundme.module').then( m => m.FundmePageModule)
  },
  {
    path: 'donation',
    loadChildren: () => import('./donation/donation.module').then( m => m.DonationPageModule)
  },
  {
    path: 'app/funding/listing',
    loadChildren: () => import('./funding/listing/funding-listing.module').then( m => m.FundingListingPageModule)
  },
  {
    path: 'app/funding/detail/:id/:slug',
    loadChildren: () => import('./funding/details/funding-details.module').then( m => m.FundingDetailsPageModule)
  },  {
    path: 'register-organization',
    loadChildren: () => import('./register-organization/register-organization.module').then( m => m.RegisterOrganizationPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
