<ion-header>
  <ion-toolbar color="primary">
    <ion-title>NOT LOGGEDIN</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col size="12" size-md="6" offset-md="3">
        <h2>Please create an account on ETFUNDME or login if you have created account with ETFUNDME.</h2>
        <ion-row>
          <ion-col>
            <ion-button expand="full" (click)="login()">LOGIN</ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="full" (click)="signup()">SIGNUP</ion-button>
          </ion-col>
        </ion-row>
        <ion-button expand="full" class="cancel-btn" (click)="cancel()">CANCEL</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
