import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languageSubject = new BehaviorSubject<string>('english');
  currentLanguage = this.languageSubject.asObservable();

  constructor() {}

  setLanguage(lang: string) {
    this.languageSubject.next(lang);
  }

  getTranslation(key: string): string {
    const translations = {
      'english': {
        'START ETFUNDME': 'START ETFUNDME',
        'Welcome to ETFUNDME.': 'Welcome to ETFUNDME.',
        'Your': 'Your ',
        'trusted platform' : 'trusted platform ',
        'to make a difference.' : 'to make a difference. ',
        'Raise funds and show your support & care to' : 'Raise funds and show your support & care to ',
        'Individuals, businesses & organizations' : 'Individuals, businesses & organizations ',
        'Browse by category' : ' Browse by category ',
        'Nonprofits' : ' Nonprofits ',
        'Startups' : ' Startups ',
        'Business' : ' Business ',
        'Religious' : ' Religious ',
        'Education' : ' Education ',
        'Other' : ' Other'
      },
      'amharic': {
        'START ETFUNDME': 'የገንዘብ ድጋፍ ጀምር',
        'Welcome to ETFUNDME.': 'እንኳን ደህና ወደ ETFUNDME መጡ',
        'Your': 'የኛ ',
        'trusted platform' : 'የታመነ መድረክ ',
        'to make a difference.' : 'ለውጥ ለማምጣት',
        'Raise funds and show your support & care to' : 'ገንዘብ በማሰባሰብ ድጋፍዎን ያሳዩ ',
        'Individuals, businesses & organizations' : 'ግለሰቦች፣ ንግዶች እና ድርጅቶች ',
        'Browse by category' : ' በአይነት ፈልግ ',
        'Nonprofits' : 'ለትርፍ ያልተቋቋሙ ድርጅቶች  ',
        'Startups' : ' ለጀማሪ አዳዲስ ስራ ፈጠራዎች ',
        'Business' : ' ንግድ ',
        'Religious' : ' ለሃይማኖታዊ ተቋማት ',
        'Education' : ' ለትምህርት',
        'Other' : ' ለሌላ ',
      }
    };

    const currentLanguage = this.languageSubject.value;
    return translations[currentLanguage][key];
  }

  changeLanguage(language: string): void {
    this.languageSubject.next(language);
  }

  getLanguage(): Observable<string> {
    return this.languageSubject.asObservable();
  }
}
