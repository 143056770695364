import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ModalController, MenuController, IonRouterOutlet, ToastController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.page.html',
  styleUrls: ['./phone-verification.page.scss'],
})
export class PhoneVerificationPage implements OnInit {
  phoneVerificationForm: FormGroup;
  timeRemaining = 180; // 3 minutes
  codeExpired = false;
  failedAttempts = 0;

  constructor(
    private formBuilder: FormBuilder, 
    private http: HttpClient,
    private authService: AuthenticationService,
    public toastController: ToastController,
    private router: Router) 
    {
      this.phoneVerificationForm = this.formBuilder.group({
        phone_number: ['', Validators.required],
        verification_code: ['', [Validators.required, Validators.pattern(/^\d{1,8}$/)]]
      });
  }

  ngOnInit() {
    window.addEventListener('beforeunload', this.preventRefresh.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.preventRefresh.bind(this));
  }

  startTimer() {
    const interval = setInterval(() => {
      this.timeRemaining -= 1;

      if (this.timeRemaining === 0) {
        clearInterval(interval);
        this.codeExpired = true;
      }
    }, 1000);
  }


  submitPhoneVerification() {
    const phoneNumber = this.phoneVerificationForm.get('phone_number').value;
    const verificationCode = this.phoneVerificationForm.get('verification_code').value;
  
    this.authService.verifyPhone(this.phoneVerificationForm.value).subscribe(
      (response) => {
        console.log('Phone verification successful:', response);
        this.router.navigate(['/auth/login']);
      },
      (error) => {
        if (error.error.detail === 'Too many failed verification attempts') {
          this.codeExpired = true; // Enable the "Resend Code" button
          this.presentTooManyAttemptsToast();
        } else if (error.error.retry) {
          const attemptsRemaining = error.error.attempts_remaining;
          this.presentRetryToast(attemptsRemaining);
        } else {
          console.error('Phone verification failed:', error);
          this.presentToast();
        }
      }
    );
  }

  
  async presentRetryToast(attemptsRemaining: number) {
    this.codeExpired = true;
    const toast = await this.toastController.create({
      message: `Invalid verification code. You have ${attemptsRemaining} attempts remaining.`,
      duration: 3000,
      buttons: [
        {
          text: 'Retry',
          handler: () => {
            // Optionally, clear the verification code input
            this.phoneVerificationForm.get('verification_code').reset();
          }
        }
      ]
    });
    toast.present();
  }
  
  

  async presentTooManyAttemptsToast() {
    this.codeExpired = false;

    const toast = await this.toastController.create({
      message: 'Too many failed verification attempts. Please request a new code.',
      duration: 3000,
      buttons: [
        {
          text: 'Resend Code',
          handler: () => {
            this.resendCode(); // Call resend code method
          }
        }
      ]
    });
    toast.present();
  }
  
  // async presentRetryToast() {
  //   const toast = await this.toastController.create({
  //     message: 'Verification failed. Please retry!',
  //     duration: 3000,
  //     buttons: [
  //       {
  //         text: 'Retry',
  //         handler: () => {
  //           this.submitPhoneVerification(); // Retry the phone verification
  //         }
  //       }
  //     ]
  //   });
  //   toast.present();
  // }
  
  async presentErrorToast() {
    const toast = await this.toastController.create({
      message: 'Verification failed. Please try again later.',
      duration: 3000
    });
    toast.present();
  }

  resendCode(): void {
    if (this.codeExpired || this.failedAttempts >= 3) {
      const phoneNumber = this.phoneVerificationForm.get('phone_number').value;
      this.authService.resendCode(phoneNumber).subscribe(
        () => {
          this.codeExpired = false;
          this.failedAttempts = 0; // Reset the failed attempts
          this.startTimer();
          // Optionally, show a toast or message indicating the code was resent
        },
        (error) => {
          console.error('Failed to resend code:', error);
        }
      );
    }
  }

  preventRefresh(event) {
    event.preventDefault();
    event.returnValue = '';
  }

  timerComplete() {
    console.log('Timer completed');
    this.router.navigate(['/signup']);  // Add this line to navigate to the signup page
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'You may have entered the wrong verification code or time expired, please review your details!',
      duration: 6000
    });
    toast.present();
  }

}