import { Component, OnInit, OnDestroy , NgZone} from '@angular/core';
import { Router } from '@angular/router';

import { SplashScreen } from '@capacitor/splash-screen';
import { Preferences } from '@capacitor/preferences';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { HistoryHelperService } from './utils/history-helper.service';
import { AuthenticationService } from './services/authentication.service';

import { Subscription } from 'rxjs';
import { MenuController } from '@ionic/angular';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent implements OnInit, OnDestroy  {

  isLoggedIn: boolean;
  private authSub: Subscription;

  appPages = [
    {
      title: 'Profile',
      url: '/auth/user',
      ionicIcon: 'person-outline'
    },
    {
      title: 'Notifications',
      url: '/auth/user',
      ionicIcon: 'notifications-outline'
    },
    {
      title: 'Activity',
      url: '/app/activity',
      ionicIcon: 'file-tray-stacked-outline',
    },
    {
      title: 'funding',
      url: 'app/funding',
      ionicIcon: 'list-outline'
    },
  ];

  accountPages = [
    {
      title: 'Log In',
      url: '/auth/login',
      ionicIcon: 'log-in-outline'
    },
    {
      title: 'Sign Up',
      url: '/auth/signup',
      ionicIcon: 'person-add-outline'
    },
    {
      title: 'Getting Started',
      url: '/getting-started',
      ionicIcon: 'rocket-outline'
    },
    {
      title: '404 page',
      url: '/page-not-found',
      ionicIcon: 'alert-circle-outline'
    }
  ];


  publicPages = [
    {
      title: 'Log In',
      url: '/auth/login',
      ionicIcon: 'log-in-outline'
    },
    {
      title: 'Sign Up',
      url: '/auth/signup',
      ionicIcon: 'person-add-outline'
    }
  ];

  textDir = 'ltr';

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private router: Router,
    private authService: AuthenticationService,
    private ngZone: NgZone,
    private menuController: MenuController,
  ) {
    this.initializeApp();
    this.setLanguage();
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {
      //cosole.log('This is normal in a browser', err);
    }
  }

  ngOnInit() {
    this.authSub = this.authService.getIsAuthenticated().subscribe((loggedIn) => {
      this.ngZone.run(() => {
        this.isLoggedIn = loggedIn;
          //cosole.log(this.isLoggedIn);
          if (this.isLoggedIn) {
            this.menuController.enable(true, 'loggedInMenu');
            this.menuController.enable(false, 'loggedOutMenu');
          } else {
            this.menuController.enable(false, 'loggedInMenu');
            this.menuController.enable(true, 'loggedOutMenu');
          }
        });
      
        this.authService.getLogoutObservable().subscribe(() => {
          this.isLoggedIn = false;
          //cosole.log(this.isLoggedIn);
        });
   });

   //this.authService.getCsrfToken().subscribe();
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public setLanguage(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
   // this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

  logout(): void {
    this.authService.logout();
  }

  public openTutorial(): void {
    // save key to mark the walkthrough as NOT visited because the user wants to check it out
    Preferences.set({
      key: 'visitedWalkthrough',
      value: 'false'
    });
    this.router.navigateByUrl('walkthrough');
  }
}
